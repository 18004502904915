import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserInfo } from '../../models/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  private readonly apiUrl: string;
  private readonly httpOptions: any;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/user-info';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
  }

  public getMy(): Observable<UserInfo> {
    return this.http.get<UserInfo>(this.apiUrl + '/my');
  }

  public getMyClinicId(): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/my/clinic-id');
  }

  public updateMyPassword(oldPassword: string, newPassword: string): Observable<any> {
    const jsonStr = `{ "oldPassword": "${oldPassword}", "newPassword": "${newPassword.replace(/['"]+/g, '')}"}`;
    const encoded: string = '"' + btoa(jsonStr) + '"';

    return this.http.patch<any>(this.apiUrl + '/my-password', encoded, this.httpOptions);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClinicLocation } from '../../models/clinic-location.model';
import { Technician } from '../../models/technician.model';
import { Doctor } from '../../models/doctor.model';
import { Assistant } from '../../models/assistant.model';
import { Admin } from '../../models/admin.model';
import { Agent } from '../../models/agent.model';
import { Clinic } from '../../models/clinic.model';

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/clinic';
  }
  public getAll(): Observable<Clinic[]> {
    return this.http.get<Clinic[]>(this.apiUrl + `/getAll`);
  }
  public getClinicClinicLocations(
    clinicId: number,
  ): Observable<ClinicLocation[]> {
    return this.http.get<ClinicLocation[]>(
      this.apiUrl + `/${clinicId}/clinic-locations`,
    );
  }

  public getClinicAdmins(clinicId: number): Observable<Admin[]> {
    return this.http.get<Admin[]>(this.apiUrl + `/${clinicId}/admins`);
  }

  public getClinicAgents(clinicId: number): Observable<Agent[]> {
    return this.http.get<Agent[]>(this.apiUrl + `/${clinicId}/agents`);
  }

  public getClinicDoctors(clinicId: number): Observable<Doctor[]> {
    return this.http.get<Doctor[]>(this.apiUrl + `/${clinicId}/doctors`);
  }

  public getClinicAssistants(clinicId: number): Observable<Assistant[]> {
    return this.http.get<Assistant[]>(this.apiUrl + `/${clinicId}/assistants`);
  }

  public getClinicTechnicians(
    clinicId: number,
    includeDeleted: boolean = false,
  ): Observable<Technician[]> {
    return this.http.get<Technician[]>(
      this.apiUrl + `/${clinicId}/technicians/${includeDeleted}`,
    );
  }
}

export const CONTACT_INFO = 'contact-info';
export const COMMUNICATION = 'communication';
export const FIRST_EXAMS = 'first-exams';
export const THERAPY = 'therapy';
export const ACTIVE_THERAPIES = 'active-therapies';
export const SEARCH_RECEIPT_AND_INVOICE = 'search-receipt-and-invoice';
export const SEARCH_INVOICES = 'search-invoices';
export const SEARCH_RECEIPTS = 'search-receipts';
export const TREATMENT_INFO = 'treatment-info';
export const APPOINTMENTS = 'appointments';
export const TECHNICIAN_ORDERS = 'technician-orders';
export const PROFORMA_INVOICES = 'proforma-invoices';
export const CASH_REGISTER = 'cash-register';
export const INVOICES = 'invoices';
export const ISSUED = 'issued';
export const RECEIPT = 'receipts';
export const ADVANCE_RECEIPT = 'advance-receipts';
export const CALENDAR = 'calendar';
export const PATIENTS = 'patients';
export const CHAT = 'chat';
export const PATIENT_ACTIONS_CATEGORIES = 'patient-action-categories';
export const ACTION_TYPES = 'action-types';
export const ACTION_TYPE = 'action-type';
export const ACTION_TYPE_DOCTOR_PRICE_TYPES = 'action-type-doctor-price-types';
export const TECHNICIAN_PHASES = 'technician-phases';
export const WORKING_HOURS = 'working-hours';
export const HOLIDAYS = 'holidays';
export const LOCATIONS = 'locations';
export const MEDIAS = 'medias';
export const PARTS_OF_DAY = 'parts-of-day';
export const PATIENT_INTERESTS = 'patient-interests';
export const PATIENT_CATEGORIES = 'patient-categories';
export const ANALYTICS = 'analytics';
export const RECEIPT_ADDRESS_CONFIG = 'receipt-address-config';
export const RECEIPT_HEADER_FOOTER_CONFIG = 'receipt-header-footer-config';
export const RECEIPT_CONFIG = 'receipt-config';
export const ADMINS = 'admins';
export const AGENTS = 'agents';
export const ASSISTANTS = 'assistants';
export const ADMINISTRATORS = 'administrators';
export const DOCTORS = 'doctors';
export const TECHNICIANS = 'technicians';
export const TECHNICIAN = 'technician';
export const WORKERS = 'workers';
export const USERS = 'users';
export const PATIENT_ANALYTICS = 'patient-analytics';
